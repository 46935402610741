import React, { FC, ReactNode, useEffect } from 'react';

import Box from '@mui/material/Box';

import { Footer } from 'components/Footer';
import { Sidebar } from 'components/Sidebar';
import { Grid, Flex, Spinner } from '@radix-ui/themes';
import { useTenants } from 'hooks/Tenant/useTenants';
import { useAuth } from 'hooks/Auth/useAuth';
import { DEFAULT_TENANT } from 'utils/constants';

interface Props {
  children: ReactNode;
}

export const Layout: FC<Props> = ({ children }) => {
  const { data: tenants, isLoading } = useTenants();
  const { tenantId, onChangeTenantId } = useAuth();

  useEffect(() => {
    if (!tenantId && tenants?.length) {
      const defaultTenant = tenants.find(
        (tenant) => tenant.label === DEFAULT_TENANT
      );
      const defaultTenantId = defaultTenant?.id || tenants[0].id;
      onChangeTenantId(defaultTenantId);
    }
  }, [onChangeTenantId, tenantId, tenants]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: (theme) => theme.palette.custom.backgroundColor,
        minHeight: '100vh',
      }}
    >
      <Grid columns="210px 1fr">
        <Box />
        <Flex direction="column">
          <Box sx={{ minHeight: 'calc(100vh - 65px)' }}>
            {isLoading ? (
              <Spinner />
            ) : (
              <Box>{children || 'Data is not loaded, sorry'}</Box>
            )}
          </Box>
          <Footer />
        </Flex>
      </Grid>
      <Sidebar />
    </Box>
  );
};
